export const CRIPTOS = [
    {
        nombre: 'BNB - BINANCE',
        red: 'BSC',
        token: 'BNB',
        llave: '0x93E5bC8415D07E4977B14D9fDbd81AEE62128A7f'

    },
    {
        nombre: 'BUSD - BINANCE',
        red: 'BSC',
        token: 'BUSD',
        llave: '0x93E5bC8415D07E4977B14D9fDbd81AEE62128A7f'

    },
    {
        nombre: 'MATIC - POLYGON',
        red: 'POLYGON',
        token: 'MATIC',
        llave: '0x93E5bC8415D07E4977B14D9fDbd81AEE62128A7f' //JHONATAN

    },

]

/*
   {
        nombre: 'BNB - BINANCE',
        red: 'BSC',
        token: 'BNB',

    },
    {
        nombre: 'BNB - BINANCE',
        red: 'BSC',
        token: 'BUSD'

    },
    {
        nombre: 'SOL - SOLANA',
        red: 'SOLANA',
        token: 'USDC'

    },
    {
        nombre: 'USDT - SOLANA',
        red: 'SOLANA',
        token: 'USDT'

    },
    {
        nombre: 'ETH - BASE HETHEREUM',
        red: 'BASE',
        token: 'ETH'

    },
* */


