/**************************************************
 * Nombre:       IconoAvatarPerfil
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Badge, IconButton, Popover} from "@mui/material";
import usus from "../../../Recursos/usuario_defecto.jpg";
import {useContext, useEffect, useState} from "react";
import {NotificationsOutlined} from "@mui/icons-material";
import {TEXTO} from "../../ContantesColor";
import Barra_NotificacionesEmergente from "../ComponentesEditables/Barra_NotificacionesEmergente";
import {contexBarra} from "../../../Dashboard/Dashboard";

const IconoNotificaciones = ({openPerfilNotificacion, setOpenPerfilNotificaciones}) => {
    const [elemetoPadre, setElementoPadre] = useState(null)
    const cData = useContext(contexBarra)
    const abrir = (e) => {
        setElementoPadre(e.target)
        setOpenPerfilNotificaciones(true)
    }

    const cerrar = (e) => {
        setElementoPadre(null)
        setOpenPerfilNotificaciones(false)
    }



    return (
        <>
            <IconButton onClick={(e) => abrir(e)}>
                <Badge color="warning" badgeContent={cData?.nuevasNotificaciones} variant='standard' sx={{
                    "& .MuiBadge-badge": {
                        color: "#fff",
                        fontWeight: 600
                    }
                }}>
                    <NotificationsOutlined src={usus} sx={{width: 20, height: 20, cursor: 'pointer', fill: '#fff'}}

                    />
                </Badge>
            </IconButton>
            <Popover
                open={openPerfilNotificacion}
                anchorEl={elemetoPadre}
                onClose={() => cerrar()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            boxShadow: '0px 8px 12px 0px #00000014',
                            border: 1,
                            borderColor: 'rgba(143,173,213,0.31)',
                            borderRadius: 2
                        }

                    }
                }}
            >
                <Barra_NotificacionesEmergente notificaciones={cData?.notificaciones} nuevas={cData?.nuevasNotificaciones}/>
            </Popover>

        </>

    )

}
export default IconoNotificaciones