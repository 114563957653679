/**************************************************
 * Nombre:       Portada_Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid2, Typography} from "@mui/material";
import fondo_img from "../../Recursos/fondo_img.png"
import fondo from "../../Recursos/video_fondo.mp4";
import logo from "../../Recursos/logo.svg";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import Formulario_Login from "../Formularios/Formulario_Login";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import {theme} from "../../Tema";
import Formulario_Registro from "../Formularios/Formulario_Registro";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useResponsive} from "../../Modulo_responsive/Hooks/useResponsive";
import {funRecuperarContrasena} from "../../Servicios/Auth/funRecuperarContrasena";
import {funIngresarCorreoPass} from "../../Servicios/Auth/funIngresarCorreoPass";
import {funCrearUsuario} from "../../Servicios/Auth/funCrearUsuario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {getID} from "../../Servicios/BD/useEscucharUsuarioToCorreo";
import {irArriba} from "../../Utilidades/irArriba";


const Portada_Login = () => {
    const {masSM, sCell, sTab} = useResponsive()
    const parms = useParams()
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const [registro, setRegistro] = useState(false)


    const ingresar = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Ingresando a panel de control')
            funIngresarCorreoPass(entidad.correo, entidad.pass).then((dox) => {
                if (dox.res) {
                    setTimeout(() => {
                        cerrarCargador()
                    }, 2000)
                } else {
                    alert(dox.data)
                    cerrarCargador()
                }


            })
        })

    }

    const registrarse = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Registrando usuario')
            funCrearUsuario(entidad.correo, entidad.pass).then((dox) => {
                if (dox.res) {
                    crearUsuario(entidad)
                } else {
                    alert(dox.data)
                    cerrarCargador()
                }

            })
        })
    }

    const crearUsuario = (entidad) => {

        let obj = entidad
        obj.id = getID(entidad.correo)
        obj.tipo = 'usuario'
        obj.fecha = new Date().getTime()
        guardarDoc('usuarios', obj).then((dox) => {
            if (dox.res) {
                setTimeout(() => {
                    cerrarCargador()
                }, 500)
            }
        })
    }

    const recuperar = () => {
        obtenerEntidad().then((entidad) => {
            abrirCargador('Enviando instrucciones a correo')
            funRecuperarContrasena(entidad.correo).then((dox) => {
                if (dox.res) {
                    alert('Se ha enviado las instrucciones de cambio de contraseña a su correo')
                } else {
                    alert(dox.data)
                }
                cerrarCargador()
            })
        })
    }


    useEffect(() => {
        if (parms && parms.id && parms.id === 'true') {
            setRegistro(true)
        } else {
            setRegistro(false)
        }
    }, []);
    return (
        <Grid2
            container
            size={12}
            sx={{marginTop: -14, alignItems: 'flex-start'}}
        >
            <video width={'100%'} height={720}
                   style={{
                       objectFit: 'cover',
                       zIndex: 1
                   }}
                   id="background-video" autoPlay loop muted poster={`url(${fondo_img})`}>
                <source src={fondo} type="video/mp4"/>
            </video>


            <Grid2 container size={12} sx={{
                justifyContent: 'center',
                backgroundImage: 'linear-gradient(0deg, rgba(0,0,0,1) 14%, rgba(0,0,0,0.6306897759103641) 31%, rgba(0,0,0,0.21052170868347342) 48%, rgba(0,0,0,0) 61%)',
                // backgroundColor: 'pink',
                zIndex: 2,
                marginTop: -101,
                alignItems: 'flex-start',

            }}>

                <Grid2 container size={12}
                       sx={{maxWidth: '1400px', px: 3, marginTop: 12, justifyContent: 'center', pb: 12, pt: 18}}>

                    <Grid2 container size={{xs: 12, sm: 6, md: 4}}
                           sx={{
                               borderRadius: 6,
                               border: 1,
                               borderColor: '#ffffff90',
                               px: !masSM ? 4 : 6,
                               py: 4,
                               backgroundColor: '#ffffff20',
                               backdropFilter: 'blur(10px)'

                           }}
                    >

                        <Cargador/>

                        <Grid2 container size={12} sx={{justifyContent: 'flex-end'}}>
                            <img src={logo} width={150} height={'auto'}/>
                        </Grid2>

                        <Grid2 container size={12} sx={{justifyContent: 'flex-start', marginTop: 4, px: 0}}>
                            <Typography sx={{
                                fontWeight: 800,
                                fontSize: sCell ? 24 : 32,
                                lineHeight: 1.1,
                                color: theme.palette.primary.main
                            }}>
                                {registro ?
                                    'Crea una cuenta en FoxPlore.'
                                    :
                                    'Iniciar sesión'
                                }
                            </Typography>
                        </Grid2>

                        <Grid2 container size={12} sx={{justifyContent: 'flex-start', marginTop: 4, px: 0}}>
                            {registro ?
                                <Formulario_Registro props={props}/>
                                :
                                <Formulario_Login props={props} recuperar={recuperar}/>
                            }

                        </Grid2>

                        <Grid2 container size={12} sx={{justifyContent: 'center', marginTop: 4, px: 2}}>


                            {registro ?
                                <Button
                                    onClick={() => registrarse()}
                                    color={'primary'} fullWidth sx={{color: '#fff', paddingTop: 1, paddingBottom: 1.2}}>Crear
                                    cuenta</Button>
                                :
                                <Button
                                    onClick={() => ingresar()}
                                    color={'primary'} fullWidth
                                    sx={{color: '#fff', paddingTop: 1, paddingBottom: 1.2}}>Iniciar
                                    sesión</Button>
                            }
                        </Grid2>

                        <Grid2 container size={12} lg={12} sm={12} xs={12}
                               sx={{justifyContent: 'center', marginTop: 4, marginBottom: 2}}>
                            <Typography sx={{color: '#fff', textAlign: 'center'}}>

                                {registro ? 'Ya tengo una cuenta' : '¿Eres nuevo/a en FoxPlore?'}
                                <span
                                    style={{
                                        color: theme.palette.primary.main,
                                        textDecoration: 'underline',
                                        paddingLeft: 5,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setRegistro(!registro)
                                        setTimeout(() => {
                                            irArriba()
                                        }, 500)
                                    }
                                    }
                                >
                    {registro ? 'Iniciar sesión' : 'Registrarse'}
                    </span>
                            </Typography>
                        </Grid2>


                    </Grid2>


                </Grid2>

            </Grid2>


        </Grid2>
    )

}
export default Portada_Login