/**************************************************
 * Nombre:       Tarjeta_Perfil
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Avatar, Grid2, Typography} from "@mui/material";
import usuario from '../../../../Recursos/usuario_defecto.jpg'
import {TEXTO, TEXTOCLARO} from "../../../ContantesColor";
import {MailOutline} from "@mui/icons-material";

const Tarjeta_Notificaciones = () => {

    return (
        <Grid2
            container
            size={12}
            sx={{alignItems: 'center'}}
        >

            <Grid2 size={1}>
                <Avatar src={usuario} sx={{width: 46, height: 46}}/>
            </Grid2>

            <Grid2 container size={10} spacing={0}  sx={{alignItems: 'center', pl: 5}}>

                <Grid2 item size={11}>
                    <Typography sx={{color: TEXTO, fontSize: 14, fontWeight: 600}}> Matheus Anderson</Typography>
                </Grid2>




                <Grid2 item size={12} sx={{marginTop: 0}} >
                    <Typography sx={{
                        color: TEXTOCLARO,
                        fontSize: 12,
                        fontWeight: 500,
                    }}> info@modernize.com</Typography>
                </Grid2>


            </Grid2>


        </Grid2>
    )

}
export default Tarjeta_Notificaciones